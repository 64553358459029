<template>
    <div class="loading" aria-live="polite" aria-busy="true">
        <div class="overlay overlay-fs" :class="{'overlay-fs': overlay}"></div>
        <div class="dancing-cube-grid">
            <div v-for="(dir,ix) in dirs" :key="ix" :class="dancing(dir)"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'loading-indicator',
    data: function data() {
        return {
            dirs: ['nw','n','ne','w','mid','e','sw','s','se'],
            overlay: true,
        };
    },
    methods: {
        dancing: function dancing(dir) {
            return 'dancing-cube dancing-cube-' + dir;
        },
    },
}
</script>
