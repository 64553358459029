import Vue from 'vue';
import App from './App.vue';
import router from './router';

import vSelect from 'vue-select';
import VueModal from '@kouts/vue-modal';
import Message from 'vue-m-message';
import 'vue-select/dist/vue-select.css';
import '@kouts/vue-modal/dist/vue-modal.css';
import 'vue-m-message/dist/index.css';

import SFPlugin from './plugins/sfplugin';

Vue.config.productionTip = false;

// https://mengdu.github.io/m-message/#/
Object.entries({
    collapsable: false,
    duration: 10000,
    showClose: true,
}).forEach(([key,val]) => Message.globals.options[key] = val);

Vue.use(Message,{name: 'toast'});
Vue.use(SFPlugin);
Vue.component('v-select',vSelect);
Vue.component('v-modal',VueModal);

new Vue({
    render: h => h(App),
    router,
    data: {
        agentList: [],
    }
}).$mount('#app');
