import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        component: () => import('@/Views/Search.vue'),
        name: 'Search',
        path: '/search',
    },
    {
        component: () => import('@/Views/History.vue'),
        name: 'History',
        path: '/history',
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes,
});

export default router;
