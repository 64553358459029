// Net module
import axios from 'axios';
import {Utils} from '../utilities/Utils';

const Trupanion = window.Trupanion,
    config = Trupanion.SFAdmin.config,
    pageSpinner = Utils.pageSpinner;

const APIURL = config('apiUrl'),
    USERSURL = config('usersUrl'),
    PARAMS = (params) => {
        const qstring = Object.entries(params)
            .map(([key,val]) => `${key}=${val}`)
            .join('&');
        return qstring ? `?${qstring}` : '';
    },
    url = ({host = APIURL, path = '',params = {}}) => {
        return host + path + PARAMS(params);
    };

let bearerToken,
    xUser;

const Urls = {
    cancelRequests: () => url({path:'/reassignpolicyrequests/cancel'}),
    exportPolicies: () => url({path:'/reports/exportpolicies'}),
    getAgents: () => url({path:'/agentoffices'}),
    getHistory: () => url({path: '/reassignpolicyrequesthistories'}),
    getPolicies: () => url({path:'/policyholders'}),
    reassignPolicy: () => url({path:'/reassignpolicyrequests'}),

    getUsers: () => url({host: USERSURL}),
};

const request = axios.create({});

function netio(method,url,data = undefined,options = {}) {
    const headers = {
        'Accept': '*/*',
        'Content-Type': 'application/json',
    };

    if (bearerToken) {
        headers.Authorization = `Bearer ${bearerToken}`;
    } else {
        console.error(`SFAPP: Net request to ${url} but no bearerToken!`);
    }
    if (xUser) {
        headers['x-user-id'] = xUser;
    }

    pageSpinner(1);
    const req = request({
        method: method,
        url: url,
        data: data,
        headers: headers,
    });
    req.finally(() => pageSpinner(-1));
    return req;
}

function netConfig(data) {
    data = data || {};
    bearerToken = data.token || bearerToken;
    xUser = data.xUser || xUser;
}

const Net = {
    config: netConfig,
    delete: (url,options) => netio('delete',url,undefined,options),
    get: (url,options) => netio('get',url,undefined,options),
    post: (url,data,options) => netio('post',url,data,options),
    put: (url,data,options) => netio('put',url,data,options),
};
export {Net, Urls};
