<template>
    <div id="sf-admin-app" :class="{'logout-active': isLogoutActive}">
        <header class="header">
            <div class="ident">
                <div class="logos">
                    <img class="tru-logo" :src="truLogo"/>
                    <img class="sf-logo" :src="sfLogo"/>
                </div>
                <div class="underwritten">
                    Underwritten by American Pet Insurance Company
                </div>
            </div>
            <span class="app-title" @click="getToken">
                Agent Reassignment Tool
            </span>
            <span v-if="isLoggedIn" class="logout icon-log-out" title="Logout" @click="logout">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="32.000000pt" height="32.000000pt" viewBox="0 0 32.000000 32.000000"
                     preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                        <path d="M142 288 c3 -8 27 -14 66 -16 l62 -3 0 -109 0 -109 -62 -3 c-39 -2
                        -63 -8 -66 -16 -3 -10 14 -12 75 -10 l78 3 0 135 0 135 -78 3 c-61 2 -78 0
                        -75 -10z"/>
                        <path d="M56 195 l-39 -35 39 -35 c47 -41 54 -43 54 -10 0 24 2 25 65 25 58 0
                        65 2 65 20 0 18 -7 20 -65 20 -63 0 -65 1 -65 25 0 33 -7 31 -54 -10z"/>
                    </g>
                </svg>
            </span>
        </header>
        <hr class="header-border"/>

        <div class="login-ctr" v-if="loaded && !isLoggedIn">
            <button class="btn clear login" @click="login">
                Please login
            </button>
        </div>

        <div class="top">
            <h2 class="welcome-ctr" v-if="loaded && isLoggedIn">
                Welcome, {{user.firstName}}
            </h2>
            <div class="nav-action">
                <ul class="nav" v-if="dataReady">
                    <li v-for="page in pages" :key="page.name" :class="{active: isActive(page)}">
                        <a class="nav-link" :class="{active: isActive(page)}"
                            @click="navTo(page)"> {{page.display}}
                        </a>
                    </li>
                </ul>
                <button class="btn std" v-if="dataReady" @click="exportAll">Export all policies</button>
            </div>
        </div>

        <hr class="header-border" v-if="dataReady"/>
        <main class="main" v-if="dataReady">
            <router-view @pagechange="selectPage" :user="user"></router-view>
            <loading-indicator v-if="isLoading"></loading-indicator>
        </main>
        <section class="main" v-show="noAgents()">
            <h3> Agent list is not available&hellip;please try later</h3>
        </section>

    </div>
</template>

<script>
import {
    decodeToken,
    getAccessToken,
    forceUserLogin,
    logout
} from '@/plugins/msal';

import { Net,Urls } from '@/utilities/Net';
import { Utils } from '@/utilities/Utils';
import loadingIndicator from '@/Components/loadingIndicator';

function expired(num) {
    const now = Date.now(),
        then = isNaN(num) ? 0 : Number(num * 1000);
    return now > then;
}

export default {
    name: 'sf-admin-app',
    components: {
        loadingIndicator,
    },
    mounted: function() {
        const vue = this;
        vue.getToken();
        vue.setSelectedPage();
        document.body.addEventListener('loading',function(evt) {
            vue.loadCnt = Math.max(0,vue.loadCnt + evt.detail);
        });
    },
    data: function() {
        return {
            accessToken: {},
            loadCnt: 0,
            loaded: false,
            isLoggedIn: false,
            isLogoutActive: false,
            user: {},
            selectedPage: {},
            pages: [
                {name: 'Search', path: '/search', display: 'Reassign Policies'},
                {name: 'History', path: '/history', display: 'View Reassignment History'},
            ],
            sfLogo: require('@/assets/state-farm-logo.png'),
            truLogo: require('@/assets/trupanion-logo.png'),
        };
    },
    computed: {
        isLoading: function() {
            const vue = this;
            return vue.loadCnt > 0;
        },
        haveAgents: function() {
            const vue = this;
            return vue.agentsList.length > 0;
        },
        dataReady: function() {
            const vue = this;
            return vue.loaded && vue.isLoggedIn && vue.accessToken.raw && vue.haveAgents;
        },
    },
    methods: {
        noAgents: function() {
            const vue = this;
            return vue.loaded && vue.isLoggedIn && vue.accessToken.raw && !vue.haveAgents && !vue.gettingAgents;
        },
        exportAll: function() {
            const vue = this;
            Net.get(Urls.exportPolicies())
                .then(data => {
                    const policies = data?.data?.exportedPolicyData,
                        SOURCES = {
                            A: 'State Farm Portal',
                            I: 'Website',
                            O: 'Other',
                            P: 'Phone',
                        };
                    // const nicePolicies = (policies || []).map(pol => {
                    //     return {
                    //         'Agent Code': pol.stateAgentCode,
                    //         'Agent Name': vue.agentName(vue.agentsList,pol.stateAgentCode),
                    //         'Policy Number': pol.policyNumber,
                    //         'Policy Enroll Date': Utils.asDateTime(pol.enrollmentDate),
                    //         'Source': SOURCES[pol.source] || pol.source,
                    //         'Owner First Name': pol.firstName,
                    //         'Owner Last Name': pol.lastName,
                    //         'Address': pol.addressLine1,
                    //         'City': pol.city,
                    //         'State': pol.state,
                    //         'Postal Code': pol.postalCode || '',
                    //         'Pets': pol.petName,
                    //     };
                    // });
                    // Utils.exportCSV(nicePolicies,'All Policies');
                    Utils.exportCSV(policies || [],'All Policies');
                });
        },
        getToken: function() {
            const vue = this,
                result = getAccessToken();

            result
                .then(raw => {
                    const b64 = decodeToken(raw),
                        token = b64 ? JSON.parse(b64) : {};
                    vue.accessToken = {
                        raw: raw,
                        obj: Object.assign({},token),
                    };
                    vue.user = {
                        firstName: token.given_name || '',
                        lastName: token.family_name || '',
                        fullName: token.name || '',
                        scp: token.scp,
                        sub: token.sub,
                    };
                    vue.loaded = true;
                    vue.isLoggedIn = !expired(token.exp);
                    if (vue.isLoggedIn) {
                        localStorage.sfaToken = raw;
                        localStorage.xUser = token.sub;
                        Net.config({token: raw,xUser: token.sub});
                        vue.getAgents();
                    } else {
                        localStorage.removeItem('sfaToken');
                        localStorage.removeItem('xUser');
                        Net.config();
                    }
                })
                .catch(e => {
                    vue.loaded = true;
                    console.log('login failed?',e);
                    vue.networkError(`Login failed: ${e.message}`);
                });
            return result;
        },
        login: async function() {
            const vue = this,
                loggedIn = await forceUserLogin();

            if (loggedIn) {
                localStorage.isLoggedIn = 'true';
                vue.isLoggedIn = true;
                vue.getToken();
            } else {
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('sfaToken');
                Net.config();
                vue.isLoggedIn = false;
                vue.accessToken = {};
            }
            vue.loaded = true;
        },
        logout: function() {
            const vue = this;
            vue.isLogoutActive = true;
            vue.$nextTick(function() {
                logout();
                localStorage.clear();
                vue.isLoggedIn = false;
            });
        },
        setSelectedPage: function() {
            const vue = this,
                locPath = document.location.pathname,
                page = vue.pages.find(p => p.path === locPath);

            vue.selectedPage = page || vue.pages[0];
        },
        selectPage: function(page) {
            const vue = this;
            vue.$nextTick(() => vue.setSelectedPage());
        },
        isActive: function(page) {
            const vue = this;
            return vue.selectedPage && vue.selectedPage.path === page.path;
        },
        navTo: function(page) {
            const vue = this;
            vue.selectedPage = page;
        }
    },
    watch: {
        selectedPage: function(page) {
            const vue = this;
            if (vue.$router.history.current.path !== page.path) {
                vue.$router.push(page);
            }
        },
    }
};
</script>

<style lang="scss">
@import "./styles/sfadmin";

.header-border {
    background: var(--gradient);
    border: none;
    height: 1px;
    margin-top: 1px;
}
header {
    align-items: center;
    background: #f4f3f1;
    display: flex;
    flex-wrap: wrap;
    min-height: 4.5em;
    justify-content: flex-start;
    .logos {
        display: flex;
    }
    .tru-logo {
        padding: 0.3em 0.5em 0 0.3em;
    }
    .sf-logo {
        border: solid #ccc;
        border-width: 0 0 0 2px;
        height: 35px;
        margin-top: 3px;
        padding-left: 0.3em;
    }
    .underwritten {
        color: #777;
        font-size: 0.65em;
        text-align: center;
    }
    .app-title {
        flex-grow: 1;
        font-size: 1.7em;
        margin-left: 2em;
    }
}

.top {
    border-width: 0 0 2px;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5em;
    &.no-border {
        border-color: transparent;
    }
}

.login-ctr {
    margin: 4em auto;
    text-align: center;
    .btn {
        transform: scale(1.6);
    }
}
.welcome-ctr {
    margin-bottom: 0;
}
.nav-action {
    display: flex;
    justify-content: space-between;
}
.nav {
    margin: 0;
    padding: 0.5em 0 0;
}
.nav li {
    cursor: pointer;
    display: inline-block;
    list-style-type: none;
    .nav-link {
        font-size: 1.2em;
        color: var(--sf_red);
        text-decoration: underline;
    }
    &.active {
        display: none;
    }
}
.main {
    margin-top: 0.3em;
}
.logout {
    cursor: pointer;
    transform: scale(0.65);
    &:hover {
        color: var(--sf_red);
    }
}
.logout-active {
    display: none;
}
</style>
