// utils module

const Trupanion = window.Trupanion;

const fullPage = {
        pageIndex: 1,
        pageSize: 99999999,
    },
    PAGER = {
        labels: {
            first: '&lt;&lt;',
            prev: '&lt;',
            next: '&gt;',
            last: '&gt;&gt;',
        },
        classes: {
            ul: 'pager center blue',
            li: 'pager-item',
            liActive: 'active',
            liDisable: 'disabled',
            button: 'page-link',
        },
    };

const SFStateIds = {
        '01': 'AL',
        '02': 'AK',
        '03': 'AZ',
        '04': 'AR',
        '55': 'CA',
        '05': 'CA',
        '75': 'CA',
        '06': 'CO',
        '07': 'CT',
        '08': 'DE',
        '09': 'DC',
        '59': 'FL',
        '11': 'GA',
        '51': 'HI',
        '12': 'ID',
        '13': 'IL',
        '14': 'IN',
        '15': 'IA',
        '16': 'KS',
        '17': 'KY',
        '18': 'LA',
        '19': 'ME',
        '20': 'MD',
        '21': 'MA',
        '22': 'MI',
        '23': 'MN',
        '24': 'MS',
        '25': 'MO',
        '26': 'MT',
        '27': 'NE',
        '28': 'NV',
        '29': 'NH',
        '30': 'NJ',
        '31': 'NM',
        '32': 'NY',
        '52': 'NY',
        '33': 'NC',
        '34': 'ND',
        '35': 'OH',
        '36': 'OK',
        '37': 'OR',
        '38': 'PA',
        '39': 'RI',
        '40': 'SC',
        '41': 'SD',
        '42': 'TN',
        '43': 'TX',
        '53': 'TX',
        '44': 'UT',
        '45': 'VT',
        '46': 'VA',
        '47': 'WA',
        '48': 'WV',
        '49': 'WI',
        '50': 'WY',
    },
    SFStateNames = {
        AK: 'Alaska',
        AL: 'Alabama',
        AR: 'Arkansas',
        AZ: 'Arizona',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DC: 'Washington, DC',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        IA: 'Iowa',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        MA: 'Massachusetts',
        MD: 'Maryland',
        ME: 'Maine',
        MI: 'Michigan',
        MN: 'Minnesota',
        MO: 'Missouri',
        MS: 'Mississippi',
        MT: 'Montana',
        NC: 'North Carolina',
        ND: 'North Dakota',
        NE: 'Nebraska',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NV: 'Nevada',
        NY: 'New York',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VA: 'Virginia',
        VT: 'Vermont',
        WA: 'Washington',
        WI: 'Wisconsin',
        WV: 'West Virginia',
        WY: 'Wyoming',
    },
    stateId = (code) => SFStateIds[code] || '',
    stateCode = (code) => String(code || '').slice(0,2),
    stateName = (code) => SFStateNames[code] || '';

function pageSpinner(incr) {
    document.body.dispatchEvent(new CustomEvent('loading',{detail: incr}));
}

function exportCSV(list,name) {
    function escaped(value) {
        if (value === null || value === undefined) {
            return '""';
        }
        return `"${String(value).replace(/"/g,'""')}"`;
    }
    function csv(list,keys) {
        // use for-loop to ensure consistent order;
        const line = [],
            result = [];

        for (let i=0; i < keys.length; i++) {
            line.push(escaped(keys[i]));
        }
        result.push(line.join(','));
        for (let i=0; i < (list || []).length; i++) {
            const line = [],
                item = list[i];
            for (let k=0; k < keys.length; k++) {
                line.push(escaped(item[keys[k]]));
            }
            result.push(line.join(','));
        }
        return result.join('\r\n');
    }

    const date = (new Date()).toISOString().replace(/T.*$/,''),
        saveLink = document.createElement('a'),
        keys = Object.keys((list || [])[0]),
        blobString = csv(list,keys),
        blob = new Blob([blobString],{type: 'application/csv'});

    saveLink.download = `${name}.${date}.csv`;
    saveLink.href = URL.createObjectURL(blob);
    document.body.appendChild(saveLink);
    saveLink.click();
    setTimeout(() => document.body.removeChild(saveLink), 1000);
}
function asDateTime(d) {
    if (!d) {
        return '';
    }
    const dt = new Date(d);
    return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
}

function exportAgentPolicies(agent,policies) {
    const list = (policies || []).map(pol => ({
            'Agent Code': agent.stateAgentCode,
            'Agent Name': agent.name,
            'Active': (pol.insuredPets || []).some(pet => pet.isActive),
            'Policy Number': pol.policyNumber,
            'Policy Enroll Date': asDateTime(pol.policyDate),
            'Owner First Name': pol.firstName,
            'Owner Last Name': pol.lastName,
            'State Code': pol.stateCode || '',
            'Postal Code': pol.postalCode || '',
            'Pets': (pol.insuredPets || [])
                .map((pet) => pet.name || '')
                .join(', '),
        }));

    exportCSV(list,agent.name);
}

const Utils = {
    asDateTime: asDateTime,
    exportAgentPolicies: exportAgentPolicies,
    exportCSV: exportCSV,
    fullPage: fullPage,
    PAGER: PAGER,
    pageSpinner: pageSpinner,
    stateIds: SFStateIds,
    stateNames: SFStateNames,
    stateId: stateId,
    stateCode: stateCode,
    stateName: stateName,
};
Trupanion.SFAdmin.Utils = Utils;

export { Utils };
