import { Net,Urls } from '@/utilities/Net';
import { Utils } from '@/utilities/Utils';

function commaize(num,symbol='',places=0) {
    const snum = Number(num).toFixed(places),
        parts = snum.split('.'),  // 123 or 123.45
        fracPart = (parts.length === 1) ? '' : ('.' + parts[1]);
    let neg = '',
        intPart = parts[0],
        result = '';

    if (isNaN(num)) {
        return '';
    }

    // Handle negative numbers.
    if (intPart.slice(0) === '-') {
        neg = '-';
        intPart = intPart.slice(1);  // strip off the negative
    }

    result = intPart.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g,'$&,');
    return neg + symbol + result + fracPart;
}

function asDateTime(dtStr = '') {
   const OPTS = {
                timeZone: 'America/Los_Angeles',
                hour12: true,
            },
        LOCALE = 'en-US',

        // ISO dateTime regular expression, from https://stackoverflow.com/a/3143231/9778003
        // see also: https://www.w3.org/TR/NOTE-datetime
        ISODATETIMERE = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/,
        dateISO = (dtStr || '').trim();

    if (dateISO.length > 0 && ISODATETIMERE.test(dateISO)) {
        const pad = s => (String(s)).padStart(2,'0'),

            // Internet Explorer has incomplete support for toLocale(Date|Time)String
            // so fall back to something, rather than breaking
            localeDateString = date => {
                try {
                    return date.toLocaleDateString(LOCALE,OPTS);
                } catch(e) {
                    try {
                        return date.toLocaleDateString(LOCALE);
                    } catch(e) {
                        return '??/??/????';
                    }
                }
            },
            localeTimeString = date => {
                try {
                    return date.toLocaleTimeString(LOCALE,OPTS);
                } catch(e) {
                    try {
                        return date.toLocaleTimeString(LOCALE);
                    } catch(e) {
                        return '??:??:?? AM';
                    }
                }
            },
            date = new Date(Date.parse(dateISO)),
            dateStr = date => {
                const [m,d,y] = localeDateString(date).split(/\//);
                return `${pad(m)}/${pad(d)}/${y}`;
            },
            timeStr = time => {
                const s = localeTimeString(date),
                    ap = s.match(/PM$/i) ? 'p':'a';
                let [h,m] = s.split(/:/);

                h = (h === '0' || h === '00') ? '12' : h;
                return `${h}:${pad(m)}${ap}`;
            };

        return `${dateStr(date)} ${timeStr(date)}`;
    } else {
        return '';
    }
}

function asLongDate(dt,andTime=false) {
    const opts = {
        day: 'numeric',
        month: 'long',
        weekday: 'long',
        year: 'numeric',
    };
    let date,
        time;
    if (typeof dt === 'string') {
        date = new Date(dt);
    }
    if (dt instanceof Date) {
        date = dt;
    }
    if (date instanceof Date) {
        time = andTime ? ' ' + date.toLocaleTimeString('en-us') : ''
        return date.toLocaleDateString('en-us',opts) + time;
    }
    return '';
}


const SFPlugin = {
    install: function(Vue,options) {
        Vue.mixin({
            data: function() {
                return {
                    gettingAgents: false,
                };
            },
            computed: {
                agentsList: function() {
                    const vue = this;
                    return vue.$root.agentList;
                },
                maxDate: function() {
                    const date = new Date();
                    date.setFullYear(date.getFullYear()+1);
                    return date.toISOString().slice(0,10);
                },
                minDate: function() {
                    return (new Date()).toISOString().slice(0,10);
                },
            },
            methods: {
                getAgents: function() {
                    const vue = this;

                    function normalizeAgent(agt) {
                        agt.stateAgentCode = vue.agentCode(agt);  // ensures SS-NNNN format
                        if (agt.name) {
                            agt.text = `${agt.stateAgentCode} -  ${agt.name}`;
                        } else {
                            agt.name = '';
                            agt.text = agt.stateAgentCode;
                        }
                        return agt;
                    }
                   
                        vue.gettingAgents = true;
                        Net.post(Urls.getAgents(),Utils.fullPage)
                            .then(agents => {
                                const list = agents?.data?.items.map(normalizeAgent)
                                list.sort((a,b) => a.stateAgentCode === b.stateAgentCode ? 0 : a.stateAgentCode < b.stateAgentCode ? -1 : 1);
                                vue.$root.agentList = list;
                            })
                            .catch(err => {
                                console.error('cannot retrieve agents',err);
                                vue.networkError(`cannot retrieve agents: ${err.message}`,{
                                    duration: -1,
                                });
                            })
                            .finally(() => vue.gettingAgents = false);
                    
                },
                agentByCode: function(list,stateAgentCode) {
                    const normalizedStateAgentCode = function(stateAgentCode) {
                        const code = (stateAgentCode || '').toLowerCase();
                        return code[2] === '-' ? code.slice(0,2) + code.slice(3) : code;
                    },
                        code = normalizedStateAgentCode(stateAgentCode);
                    return list.find(agt => normalizedStateAgentCode(agt.stateAgentCode) === code);
                },
                agentName: function(list,agentCode) {
                    const vue = this;
                    if (Array.isArray(list)) {
                        const agent = vue.agentByCode(list,agentCode);
                        return agent ? agent.name : agentCode;
                    } else {
                        const agent = list;
                        return agent ? agent.name : agentCode;
                    }
                },
                agentCode: function(agent) {
                    const agt = agent || {},
                        code = agt.stateAgentCode || '',
                        state = code.slice(0,2),
                        rest = code.slice(2).replace(/^-+/,'');

                    return `${state}-${rest}`;
                },
                networkError: function(msg,opts={}) {
                    const vue = this;
                    vue.$toast.error({
                        message: msg,
                        title: 'Network error',
                        ...opts
                    });
                },
            },
            filters: {
                phoneFmt: (number = '') => String(number).replace(/\D/g,'').replace(/^(\d{3})(\d{3})(\d{4})$/,'($1) $2-$3'),
                commaize: (num) => commaize(num),
                currency: (num) => commaize(num,'$',2),
                asDate: (dtStr = '') => asDateTime(dtStr).slice(0,10),
                asLongDate: (dtStr) => asLongDate(dtStr),
                asLongDateTime: (dtStr) => asLongDate(dtStr,true),
            }
        });
    },
};
export default SFPlugin;
