import {
      PublicClientApplication,
      LogLevel,
} from '@azure/msal-browser';

import { Base64 } from 'js-base64';

const config = window.Trupanion.SFAdmin.config,
    RESETPWD = config('msal.resetPwd'),
    SIGNIN = config('msal.signIn'),
    CLIENTID = config('msal.clientId'),
    TOKENSCOPE = config('msal.tokenScope');


/**
 * This are the user flow login policies
 */
const Authorities = {
      resetPwd: RESETPWD, // https://devtrupstatefarmb2c.b2clogin.com/devtrupstatefarmb2c.onmicrosoft.com/B2C_1_PasswordReset',
      signIn: SIGNIN, // 'https://devtrupstatefarmb2c.b2clogin.com/tfp/devtrupstatefarmb2c.onmicrosoft.com/B2C_1_SignIn',
};

/**
 * This is the configuration for our connection to Azure B2C
 *
 * TODO we need to move clientId value to an env file
 */
const MsalConfig = {
    auth: {
        authority: Authorities.signIn,
        clientId: CLIENTID, // '5deb8c4b-bbc0-4c3d-96dc-73669e307c7d',
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error: console.error(message); break
                    case LogLevel.Info: console.info(message); break
                    case LogLevel.Verbose: console.debug(message); break
                    case LogLevel.Warning: console.warn(message); break
                };
            },
        },
    }
};

/**
 * This would be used if we were using loginPopup, for ID tokens only.
 * We are not using this as we are using a hybrid flow retrieveing
 * accessToken and idToken
 */

/**
 * We are using this request for login to retrieve application scopes.
 * This is used to get access tokens, but in conjuction with acquireTokenPopup
 * will also return idToken
 */
const TokenRequest = {
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
    scopes: [TOKENSCOPE], // 'https://devtrupstatefarmb2c.onmicrosoft.com/api/user.admin',
}

/**
 * This is a configured instance of our connection to Azure B2C
 */
const MsalInstance = new PublicClientApplication(MsalConfig);

/**
 * Function to logout
 */
const logout = async () => {
    localStorage.token = '';
    localStorage.nickname = '';
    localStorage.username = '';
    await MsalInstance.logout();
}

const assignLocalValues = (loginResponse) => {
    if (loginResponse && loginResponse.account) {
        const first = loginResponse.account.idTokenClaims.given_name,
            last = loginResponse.account.idTokenClaims.family_name[0],
            username = loginResponse.account.idTokenClaims.username;
        localStorage.nickname = `${first} ${last}`;
        localStorage.username = username;
        localStorage.token = loginResponse.idToken ? loginResponse.idToken : '';
    }
}

/**
 * Forces the login process.  If attemptSilent is true (default) will attempt to retrive the token silently (ie no popup).
 * If that fails will recursively call itself with attemptSilent = false, which forces the popup dialog.
 */
const forceUserLogin = async (attemptSilent = true) => {
    let response;
    if (localStorage.username && attemptSilent) {
        const request = Object.assign({ account: localStorage.username }, TokenRequest);
        const loginResponse = await MsalInstance.acquireTokenSilent(request).catch(async () => {
            response = await forceUserLogin(false);
            return null;
        });
        if (loginResponse !== null) {
            assignLocalValues(loginResponse);
            response = true;
        }
    } else {
        const loginResponse = await MsalInstance.acquireTokenPopup(TokenRequest);
        if (loginResponse !== null) {
            assignLocalValues(loginResponse);
            response = true;
        }
    }
    return response;
}

/**
 * Attempt to authenticate via sso
 */
const ssoLogin = async () => {
    const request = Object.assign({
            loginHint: localStorage.username
        }, TokenRequest);
    const loginResponse = await MsalInstance.ssoSilent(request).catch(() => null);
    if (loginResponse !== null) {
        assignLocalValues(loginResponse);
    }
    return (loginResponse && loginResponse.account !== null);
}

/**
 * Expose the method to get a current access token, typically called before we make API calls.
 */
const getAccessToken = async () => {
    try {
        const requestToken = Object.assign({}, TokenRequest);
        const request = Object.assign({ account: localStorage.username }, requestToken);
        const response = await MsalInstance.acquireTokenSilent(request);
        if (response.accessToken) {
            return response.accessToken;
        } else {
            return '';
        }
    } catch (err) {
        return '';
    }
}

const decodeToken = (token) => {
    try {
        if (!token) {
            return;
        }

        const parts = token.split('.');
        if (!parts || parts.length !== 3) {
            return;
        }

        const payload = Base64.decode(parts[1]);
        return payload;
    } catch (err) {
        return '';
    }
}

export {
    decodeToken,
    forceUserLogin,
    getAccessToken,
    logout,
    ssoLogin,
};
